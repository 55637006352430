export default class ProductAnalyzer {
  changeDateType(element) {
    const today = new Date
    const nextDay = new Date(today.getTime() + (24 * 60 * 60 * 1000));
    const next2Days = new Date(today.getTime() + (24 * 60 * 60 * 1000) * 2);
    if($(element).val() === '') {
      $('#dateAnalyze').val(null)
      $(element).parents('form').submit()
    } else if($(element).val() === 'today') {
      $('#dateAnalyze').val(shared.convertDate(today))
      $(element).parents('form').submit()
    } else if ($(element).val() === 'next_day') {
      $('#dateAnalyze').val(shared.convertDate(nextDay))
      $(element).parents('form').submit()
    } else if ($(element).val() === 'next_2_days') {
      $('#dateAnalyze').val(shared.convertDate(next2Days))
      $(element).parents('form').submit()
    } else {
      $('#dateAnalyze').toggleClass('d-none', $(element).val() !== 'custom')
      $('#dateAnalyze').focus()
    }
  }
}

/**
 * 「チェックしたものを〇〇する」関連
 * linkExpr         アクションリンクのセレクタ
 *                  data-confirm="表示する文言" を設定すると、confirmが挟まれる
 *                  data-method="post" でPOSTリクエストといった具合に、遷移先のRequestMethodを変更可能
 * formExpr         チェックボックスを内包しているform要素のセレクタ
 * checkboxExpr     $(formExpr) に内包されているチェックボックスのセレクタ
 *                  value="アイテムID" としておく必要がある
 */
;(($) => {
  const linkExpr = '.js-bulk-action-link';
  const formExpr = '.js-bulk-action-form';
  const checkboxExpr = '.js-bulk-action-checkbox';
  let $links, $form, $checkboxes;

  const init = () => {
    $links = $(linkExpr);
    if (!$links.length) return;
    $form = $(formExpr);
    if (!$form.length) return;
    $checkboxes = $form.find(checkboxExpr);
    if (!$checkboxes.length) return;

    $('body').on('click', linkExpr, onClick);
  };

  const onClick = e => {
    const $element = $(e.currentTarget);
    if (!$checkboxes.filter(':checked').length) {
      alert('対象のデータが選択されていません。');
      return false;
    }
    if ($element.data('confirm')) {
      if (!confirm($element.data('confirm'))) return false;
    }
    shadowSubmit($element.attr('href'), $element.data('method'));
    return false;
  };

  const shadowSubmit = (url, method) => {
    const $shadowForm = $('<form></form>');
    $shadowForm.attr({
      action: url,
      'accept-charset': 'UTF-8',
      method: 'get'
    });
    if (method == null) {
      method = 'get';
    }
    if (method == 'post') {
      $shadowForm.attr('method', 'post');
    }
    if (method == 'put' || method == 'patch' || method == 'delete') {
      $shadowForm.attr('method', 'post');
      $shadowForm.append($('<input />').attr({
        type: 'hidden',
        name: '_method',
        value: method
      }));
    }
    $.each($form.serializeArray(), (key, val) => {
      const $input = $('<input />');
      $input.attr({
        type: 'hidden',
        name: val.name,
        value: val.value
      });
      $shadowForm.append($input);
    });
    $('body').append($shadowForm);
    $shadowForm.submit();
  };

  $(init);

})(jQuery);

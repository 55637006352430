/**
 * 検索フォーム関連
 * formExpr       form要素のセレクタ
 * textInputExpr  input[type="text"] または textarea要素のセレクタ
 *                $(formExpr) に内包されている必要がある
 * inputExpr      textInputExpr以外の要素のセレクタ
 *                checkbox, select, radio buttonなど
 */
;(($) => {
  const formExpr = '.js-search-form';
  const textInputExpr = '.js-search-text-input';
  const inputExpr = '.js-search-input';
  const waitInterval = 1000;
  let $form, timer;

  const init = () => {
    $form = $(formExpr);
    if (!$form.length) return;

    $form.
      on('keyup', textInputExpr, onTextInputChanged).
      on('change', inputExpr, onInputChanged);
  };

  const onTextInputChanged = e => {
    clearTimeout(timer);
    timer = setTimeout(submitData, waitInterval);
  };

  const onInputChanged = e => {
    submitData();
  };

  const submitData = () => {
    $form.submit();
  };

  $(init);

 })(jQuery);

/**
 * OrderComment更新フォーム関連
 * formWrappersExpr       操作対象のフォームの外側の要素のセレクタ
 *                        以下の要素は全てこのwrapper要素内に
 *                        入っている必要がある
 * editButtonExpr         編集ボタンのセレクタ
 * editCancelButtonExpr   編集キャンセルボタンのセレクタ
 * textExpr               表示テキストのセレクタ
 * formExpr               フォームのセレクタ
 */
;(($) => {
  const formWrappersExpr = '.js-comment-form-wrapper'
  const editButtonExpr = '.js-comment-edit-button'
  const editCancelButtonExpr = '.js-comment-edit-cancel-button'
  const textExpr = '.js-comment-text'
  const formExpr = '.js-comment-form'

  const init = () => {
    toggleDisplay()
  };

  const toggleDisplay = () => {
    const formWrappers = $(formWrappersExpr)
    formWrappers.each((_, wrapper) => {
      const editButton = $(wrapper).find(editButtonExpr)
      const editCancelButton = $(wrapper).find(editCancelButtonExpr)
      const form = $(wrapper).find(formExpr)
      const text = $(wrapper).find(textExpr)

      $(editCancelButton).hide()
      $(form).hide()

      $(editButton).on({
        click: () => {
          $(editButton).hide()
          $(text).hide()
          $(form).show()
          $(editCancelButton).show()
        }
      })

      $(editCancelButton).on({
        click: () => {
          $(editButton).show()
          $(text).show()
          $(form).hide()
          $(editCancelButton).hide()
        }
      })
    });
  }

  $(init);

})(jQuery);

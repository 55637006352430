const feather = require('feather-icons');

/**
 * 出品者::配送設定 - 「送料設定を追加する」削除
 * @require feather-icons
 */
;($ => {
  const expr = '.js-append-shipping-cost-condition';
  const deleteExpr = '.js-append-shipping-cost-condition-delete';
  const itemExpr = '.js-append-shipping-cost-condition-item';
  const deleteHiddenExpr = '[name="shipping_zone[shipping_cost_conditions][][_delete]"]';

  const init = () => {
    const $elements = $(expr);
    if (!$elements.length) return;

    $('body').
      on('click', expr, onAddClick).
      on('click', deleteExpr, onDeleteClick);

    $elements.each((key, element) => {
      const $element = $(element);
      const $target = $($element.data('target'));
      const $template = $($($element.data('template')).html());
      $template.find('[type="number"]').val('');
      $element.
        prop('$target', $target).
        prop('$template', $template);
      if (!$target.find(itemExpr).length) {
        $element.trigger('click');
      }
    });
  };

  const onAddClick = e => {
    const $element = $(e.currentTarget);
    const $target = $element.prop('$target');
    const $template = $element.prop('$template').clone();
    $target.append($template);
    feather.replace();
    return false;
  };

  const onDeleteClick = e => {
    const $element = $(e.currentTarget);
    const $parent = $element.parents(itemExpr);
    const persisted = $parent.data('persisted');
    if (persisted) {
      $parent.find(deleteHiddenExpr).val(true);
      $parent.hide();
      return false;
    }
    $parent.remove();
    return false;
  };

  $(init);

})(jQuery);

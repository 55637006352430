/**
 * チェック状態で要素の表示/非表示
 * expr  チェックボックスのセレクタ
 *       data-show-target="セレクタ" チェック時に表示する要素のセレクタ（アンチェック時は非表示）
 *       data-hide-target="セレクタ" チェック時に非表示表示する要素のセレクタ（アンチェック時は表示）
 */
;(($) => {
  const expr = '.js-toggle-checkbox';

  const init = () => {
    $('body').on('change', expr, onChange);
    $(expr).trigger('change');
  };

  const onChange = e => {
    const $element = $(e.currentTarget);
    const showTarget = $element.data('show-target');
    const hideTarget = $element.data('hide-target');

    if ($element.prop('checked')) {
      if (showTarget != null) $(showTarget).show();
      if (hideTarget != null) $(hideTarget).hide();
      return false;
    }
    if (showTarget != null) $(showTarget).hide();
    if (hideTarget != null) $(hideTarget).show();
    return false;
  };

  $(init);

})(jQuery);

import { easepick } from '@easepick/core';
import { RangePlugin } from '@easepick/range-plugin';

const form = '.js-search-form'
const btnOrderFilter = '#btnOrderFilter'
const btnShippingFilter = '#btnShippingFilter'
const additionalFilter1 = '#additional-filter1'
const additionalFilter = '#additional-filter'
const orderElement = 'filter_by_order_date'
const scheduleElement = 'filter_by_shipping_date'
const estimateElement = 'filter_by_delivery_date'
const additionalFilterId = 'additional-filter'

const pickerOption = (element) => ({
  element: document.getElementById(element),
  css: [
    'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
  ],
  plugins: [RangePlugin],
  RangePlugin: {
    tooltipNumber(num) {
      return num - 1;
    },
    locale: {
      one: 'day',
      other: 'days',
    },
  },
  setup: (picker) => {
    picker.on('hide', (date) => {
      console.log()
      $(form).submit()
    })
  },
  position: 'right',
  lang: 'ja-JP',
})

if($('#filter_by_order_date').length > 0) {
  const schedulePicker = new easepick.create(pickerOption(orderElement));
}
if($('#filter_by_shipping_date').length > 0) {
  const schedulePicker = new easepick.create(pickerOption(scheduleElement));
}
if($('#filter_by_delivery_date').length > 0) {
  const estimatePicker = new easepick.create(pickerOption(estimateElement));
}

$(btnOrderFilter).on({
  click: (e) => {
    if ($(additionalFilter1).hasClass('show')) {
      $(e).removeClass('show').data('toggle', '')
      $(additionalFilter1).removeClass('show')
    } else {
      $(e).addClass('show').data('toggle', 'dropdown')
      $(additionalFilter1).addClass('show')
    }
  }
})

$(btnShippingFilter).on({
  click: (e) => {
    if ($(additionalFilter).hasClass('show')) {
      $(e).removeClass('show').data('toggle', '')
      $(additionalFilter).removeClass('show')
    } else {
      $(e).addClass('show').data('toggle', 'dropdown')
      $(additionalFilter).addClass('show')
    }
  }
})

window.onclick = function (e) {
  if (!document.getElementById(additionalFilterId)) { return; }

  if (!document.getElementById(additionalFilterId).contains(e.target) && e.target != $(btnShippingFilter)[0]) {
    console.log(1)
    $(additionalFilter).removeClass('show')
  }
}

export default class Sidebar {
  toggle() {
    if(cookie.get('isCollapse')) {
      sidebar.expand()
    } else {
      sidebar.collapse()
    }
  }

  expand() {
    $('#arrow-collapse').removeClass('d-none')
    $('#arrow-expand').addClass('d-none')
    $('.sidebar-toggle').removeClass('is-expand')
    $('#sidebarMenu').addClass('d-md-inline-flex').removeClass('d-md-none')
    $('main').removeClass('col-md-12').addClass('col-lg-10 col-md-9')
    cookie.erase('isCollapse')
  }

  collapse() {
    $('#arrow-collapse').addClass('d-none')
    $('#arrow-expand').removeClass('d-none')
    $('.sidebar-toggle').addClass('is-expand')
    $('#sidebarMenu').removeClass('d-md-inline-flex').addClass('d-md-none')
    $('main').removeClass('col-lg-10 col-md-9').addClass('col-md-12')
    cookie.set('isCollapse', 'true')
  }
}

/**
 * 「開く」リンク
 */
;(($) => {
  const expr = '.js-opener-anchor';

  const init = () => {
    $('body').on('click', expr, onClick);
  };

  const onClick = e => {
    const $element = $(e.currentTarget);
    const $target = $($element.data('target'));
    $target.show();
    $element.hide();
    return false;
  };

  $(init);

})(jQuery);
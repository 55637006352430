import axios from 'axios';
import bsCustomFileInput from 'bs-custom-file-input';

/**
 * インポート画面用JS
 * ※ モーダルの要素に data-backdrop="static" を設定しておく
 * @require bsCustomFileInput
 */
;($ => {

  const expr = '.js-import-modal';
  const formExpr = '.js-import-modal-form';
  const fileExpr = '.js-import-modal-form-file';
  const submitExpr = '.js-import-modal-form-submit';
  const progressExpr = '.js-import-modal-progress';
  let $modal;
  let specifiedFile;

  const init = () => {
    $modal = $(expr);
    if (!$modal.length) return;

    bsCustomFileInput.init();
    $modal.
      on('submit', formExpr, onSubmitted).
      on('change', fileExpr, onChanged).
      on('hidden.bs.modal', onModalHidden);
  };

  const onSubmitted = e => {
    const $form = $(e.currentTarget);
    if (specifiedFile == null) {
      alert('アップロードするファイルを選択してください。');
      return false;
    }
    _sendRequest($form);
    return false;
  };

  const onChanged = e => {
    const $file = $(e.currentTarget);
    const $form = $file.parents(formExpr);
    const $submit = $form.find(submitExpr);
    specifiedFile = $file.prop('files')[0];
    if (specifiedFile != null) {
      $submit.removeClass('disabled');
      return false;
    }
    $submit.addClass('disabled');
    return false;
  };

  const onModalHidden = e => {
    $modal.find(progressExpr).hide();
    $modal.find('.on-complete').hide();
    $modal.find('.initial').show();
  };

  const onSucceed = response => {
    $modal.find(progressExpr).hide();
    $modal.find('.initial').hide();
    $modal.find('.on-complete').show();
  };

  const _sendRequest = async $form => {
    const requestUrl = $form.attr('action');
    const formData = new FormData($form[0]);
    const $progress = $modal.find(progressExpr);
    let response;
    $progress.show();
    try {
      response = await axios.post(requestUrl, formData);
    } catch (error) {
      console.error(error.response.status);
      alert('アップロード中にエラーが発生しました。時間をおいて再度お試しください。');
      $progress.hide();
      return;
    }
    setTimeout(() => {
      onSucceed(response);
    }, 1000);
  };

  $(init);

})(jQuery);

import jQuery from 'jquery'

/**
 * Datepickerを使って日付を絞り込む
 * datepickerFieldExpr フォームのセレクタ
 */
 ;(($) => {
  const datepickerFieldExpr = '.js-datepicker';
  const datepickerFormExpr = '.js-date-search-form'

  const init = () => {
    activateDatepicker();
  };

  const activateDatepicker = () => {
    $.datepicker.regional['ja'] = {
      prevText: '前',
      nextText: '次',
      monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
      dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
      dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
    };

    $.datepicker.setDefaults(
      $.extend(
        $.datepicker.regional['ja']
      )
    );

    $(datepickerFieldExpr).datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: () => {
        console.log($(datepickerFormExpr))
        $(datepickerFormExpr).submit()
      }
    });
  };

  $(init);

})(jQuery);

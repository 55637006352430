export default class Shared {
  reload(confirmText) {
    if (confirm(confirmText)) location.reload()
  }

  convertDate(date) {
    const d = new Date(date)
    let month = (d.getMonth() + 1).toString()
    let day = d.getDate().toString()
    const year = d.getFullYear()
    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }
    return [ year, month, day ].join('-')
  }
}

export default class Textarea {
  autoSize() {
    $(document).ready(function() {
      $('textarea').on('keyup keypress', function() {
        $(this).height(0);
        $(this).height(this.scrollHeight);
      });

      $("textarea").each(function(textarea) {
        $(this).height(0);
        $(this).height(this.scrollHeight);
      });

    });
  }
}

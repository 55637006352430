// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()
// import jQuery from 'jquery';
// import 'jquery'
// window.$ = window.jquery = window.jQuery = jquery
// global.$ = global.jquery = global.jQuery = jquery

// window.jQuery = window.$ = require('jquery')

try {
  console.log('application.js: ', jQuery)
} catch (error) {console.error(error);}
try {
  // console.log('application.js: ', jquery)
} catch (error) {console.error(error);}
try {
  console.log('application.js: ', $)
} catch (error) {console.error(error);}

import 'bootstrap'
try {
  // console.log('application.js: ', jQuery().popover)
} catch (error) {console.error(error);}
try {
  // console.log('application.js: ', jquery().popover)
} catch (error) {console.error(error);}
try {
// console.log('application.js: ', $().popover)
} catch (error) {console.error(error);}

import '@fortawesome/fontawesome-free/js/all'
import 'feather-icons/dist/feather'
import '../stylesheets/application.scss'
import 'jquery-ui/ui/widgets/datepicker'
import Swal from 'sweetalert2/dist/sweetalert2.js'
// window.$ = window.jquery = window.jQuery = jQuery
// global.$ = global.jquery = global.jQuery = jQuery

const feather = require('feather-icons')
;(($) => {
  $(() => {
    feather.replace();
  });
})(jQuery);

import '../src/bulk_action'
import '../src/bulk_checkbox'
import '../src/ckeditor'
import '../src/search_form'
import '../src/opener'
import '../src/order_comment_form'
import '../src/text_input_sync'

console.log(111111)
import '../src/toggle_checkbox'
import '../src/product_form'
import '../src/drop_uploader'
import '../src/drop_uploader_variant'
import '../src/import_modal'
import '../src/tracking_url_form'
import '../src/append_shipping_cost_condition'
import '../src/admin/review_request_link'
import '../src/datepicker'
import '../src/line_items_daterange_filter'
import '../src/variant_cost_calculate'
import '../src/open_print'
import '../src/search_customer'
require("shopify_app")

import Collapsible from '../src/components/collapsible';
import Shared from "../src/components/shared";
import Checkbox from "../src/components/checkbox";
import ProductStock from "../src/pages/product_stock";
import ProductAnalyzer from "../src/pages/product_analyzer";
import Textarea from "../src/components/textarea";
import DefaultRun from "../src/default_run";
import Sidebar from "../src/components/sidebar";
import Cookie from "../src/components/cookie";

window.Swal = Swal
window.collapsible = new Collapsible()
window.shared = new Shared()
window.checkbox = new Checkbox()
window.productStock = new ProductStock()
window.productAnalyzer = new ProductAnalyzer()
window.textArea = new Textarea()
window.sidebar = new Sidebar()
window.cookie = new Cookie()
const defaultRun = new DefaultRun()
defaultRun.execute()

// sidebar collapse
var $merchantSettingsLink = $('a[href="#collapseExample"]');
$merchantSettingsLink.on('click', function (e){
  $merchantSettingsLink.find('.feather-chevron-right').toggleClass('d-none');
  $merchantSettingsLink.find('.feather-chevron-down').toggleClass('d-none');
});

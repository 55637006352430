/**
 * 【表示用】Variantの「価格」から「商品1点あたりの費用」を計算
 */
;(($) => {

  const expr = '.js-variant-cost-calculate';

  const init = () => {
    $('body').on('change keyup', expr, onChanged);
    $(expr).trigger('change');
    if ($(expr).length && !['percent', 'yen'].includes($(expr).first().data('shopFeeType'))) {
      alert("仕入れ値設定がされていません。管理者に問い合わせください。")
    }
  };

  const onChanged = e => {
    const $element = $(e.currentTarget);
    const price = $element.val();
    const $for = $($element.data('for'));
    // shop_fee calculation
    const shop_fee_type = $element.data('shopFeeType');
    const shop_fee = shop_fee_type == "percent" ? Math.floor(price * $element.data('shopFeePercent') / 100) : $element.data('shopFeeYen');
    const payment_fee = Math.floor(price * $element.data('paymentFeePercent') / 100);
    const cost = Math.floor(price - (shop_fee + payment_fee) );

    $for.val(cost);
  };

  $(init);

})(jQuery);

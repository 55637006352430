/**
 * text inputの入力内容を反映
 */
;(($) => {
  const expr = '.js-open-print';

  const init = () => {
    openPrint()
  };

  const openPrint = () => {
    if ($(expr).length > 0) {
      $('nav').hide()
      window.print();
    }
  }

  $(init);

})(jQuery);

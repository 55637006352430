/**
 * Customer 検索
 * customerFormExpr       Customer 検索フォーム
 * customerListExpr       Customer 予測リスト
 * customerBtnExpr        Customer 選択ボタン
 * customerIdFieldExpr    Customer ID 隠しフィールド
 * customerNameExpr       Customer 名前表示
 * customerLabelExpr      Customer 名前ラベル
 * customerFindLabelExpr  Customer 検索ラベル
 * customerRemoveExpr     Customer 削除ボタン
 */
 ;(($) => {
  const customerFormExpr      = '.js-customer-form';
  const customerListExpr      = '.js-customer-list';
  const customerBtnExpr       = '.js-customer-btn';
  const customerIdFieldExpr   = '#js-customer-id-field';
  const customerNameExpr      = '.js-customer-name';
  const customerLabelExpr     = '.js-customer-label';
  const customerFindLabelExpr = '.js-customer-find-label';
  const customerRemoveExpr    = '.js-customer-remove';

  const init = () => {
    $(customerListExpr).hide()
    $(customerRemoveExpr).hide()
    $(customerLabelExpr).hide()
    $(customerFormExpr).on({
      'input': (input) => {
        $.ajax({
          url: '/merchant/customers/search?name=' + input.target.value,
          type: 'GET',
          dataType: 'json'
        }).done((data) => {
          console.log(data)
          $(customerListExpr).empty()
          data.forEach((customer) => {
            const customerName = (customer.lastName || '') + ' ' + (customer.firstName || '')
            $(customerListExpr).append(
              '<button type="button" class="btn btn-outline js-customer-btn" data-customer-id="' +
                customer.id + '" data-customer-name="' + customerName + '">' +
                customerName + '</button>'
            )
          })
          prepareCustomerBtn()
        }).fail(() => {
          console.log('error')
        })
      },
      'focusin': () => {
        $(customerListExpr).show()
      }
    });

    $(customerRemoveExpr).on({
      'click': () => {
        $(customerIdFieldExpr).val(null)
        $(customerNameExpr).html(null)
        $(customerRemoveExpr).hide()
        $(customerFormExpr).show()
        $(customerLabelExpr).hide()
        $(customerFindLabelExpr).show()
      }
    });

    const prepareCustomerBtn = () => {
      $(customerBtnExpr).each((_, element) => {
        element.onclick = () => {
          $(customerListExpr).hide()
          $(customerIdFieldExpr).val(
            element.getAttribute('data-customer-id')
          )
          $(customerNameExpr).html(element.getAttribute('data-customer-name'))
          $(customerRemoveExpr).show()
          $(customerFormExpr).hide()
          $(customerLabelExpr).show()
          $(customerFindLabelExpr).hide()
        }
      });
    }
  };

  $(init);

})(jQuery);

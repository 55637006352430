/**
 * text inputの入力内容を反映
 */
 ;(($) => {
  const expr = '.js-text-input-sync';

  const init = () => {
    $('body').on('keyup', expr, onKeyup);
  };

  const onKeyup = e => {
    const $element = $(e.currentTarget);
    const $target = $($element.data('target'));
    $target.html($element.val());
    return false;
  };

  $(init);

})(jQuery);

/**
 * トラッキングURL入力フォームの表示/非表示切り替え
 * trackingCompanySelectExpr  配送業者のセレクトタグのセレクタ
 * trackingUrlFormWrapperExpr トラッキングURLのフォームの親要素
 */
 ;(($) => {
  const trackingCompanySelectExpr = '.js-tracking-company-select'
  const trackingUrlFormWrapperExpr = '.js-tracking-url-form-wrapper'

  const init = () => {
    toggleTrackingUrlForm()
  };

  const toggleTrackingUrlForm = () => {
    const trackingCompanySelect = $(trackingCompanySelectExpr)
    const trackingUrlFormWrapper = $(trackingUrlFormWrapperExpr)

    if ($(trackingCompanySelect).val() !== 'その他') {
      $(trackingUrlFormWrapper).hide()
    }

    $(trackingCompanySelect).on({
      change: () => {
        if ($(trackingCompanySelect).val() === 'その他') {
          $(trackingUrlFormWrapper).show()
        } else {
          $(trackingUrlFormWrapper).hide()
          $(trackingUrlFormWrapper).find('input').val(null)
        }
      }
    })
  }

  $(init);

})(jQuery);

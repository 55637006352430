/**
 * CKEditorセットアップ
 * 使用したいtextarea要素にクラス名 js-cktext-area を設定する
 * @require CKEditor
 */
;(($) => {
  const textareaClass = 'js-cktext-area';
  const init = () => {

    // plugin settings
    ["justify", "image2", "youtube", "autogrow", "imageresponsive", "imageresponsive", "imageresizerowandcolumn", "imageresize"].forEach(n => {
      CKEDITOR.plugins.addExternal( n, `/ckeditor/plugins/${n}/`, 'plugin.js' );
    })

    CKEDITOR.on("instanceReady", function(ev) {
      var editor = ev.editor;
      // editor.on("focus", function(ev) {
      //   console.log("focused!");
      // });
      editor.on("required", function(ev) {
        // alert('入力が必須項目です');
        editor.showNotification( '入力が必須項目です', 'warning' );
        // window.scrollTo(0, ev.editor.element.$.offsetTop);
        // window.scrollTo(0, document.getElementsByClassName(ev.editor.id)[0].getBoundingClientRect().top)
        // ev.editor.element.$.scrollIntoView();
        document.getElementById("cke_" + editor.name).scrollIntoView();
        ev.cancel();
        event.preventDefault();
        // event.target.querySelectorAll("input[type=submit]").forEach(function (elm) { elm.disabled = false; })
        setTimeout(() => {
          ev.editor.element.$.form.querySelectorAll("input[type=submit]").forEach(function (elm) {
            elm.disabled = false;
          })
        }, 100);
      });
    });
    CKEDITOR.replaceAll(_replace);

  };
  const _replace = (textarea, config) => {
    console.log(CKEDITOR.instances)
    console.log(textarea.id)
    // Disable security notifications.
    config.versionCheck = false;

    // https://stackoverflow.com/questions/30214555/ckeditor-4-replaceall-with-custom-config
    if (!$(textarea).hasClass(textareaClass)) return false;
    // set required for MS-Amu
    // textarea.setAttribute("required", "true");
    if (["merchant_description", "merchant_description2"].includes(textarea.id)) {
      config.extraPlugins = 'notification,fakeobjects,autogrow,emoji,justify';
      // config.removePlugins = "image"
      config.removeButtons = 'Image';
    } else {
      config.extraPlugins = 'notification,fakeobjects,autogrow,emoji,justify,youtube,image2,uploadimage,imageresponsive,imageresizerowandcolumn,imageresize';
    }

    if (textarea.id == 'product_descriptionHtml') {
      // CKEDITOR.instances['product_descriptionHtml'].on('contentDom', function (ev) {
      //   this.document.on('click', function (event) {
      //     //your code
      //     console.log('Click Event');
      //   });
      //   console.log(ev)
      // });
      // CKEDITOR.instances['product_descriptionHtml'].on('required', function (ev) {
      //   // alert('商品説明 は入力が必須項目です');
      //   CKEDITOR.instances['product_descriptionHtml'].showNotification( '入力が必須項目です', 'warning' );
      //   document.getElementById("cke_" + ev.editor.name).scrollIntoView();
      //   ev.cancel();
      //   event.preventDefault();
      //
      //   setTimeout(() => {
      //     ev.editor.element.$.form.querySelectorAll("input[type=submit]").forEach(function (elm) {
      //       elm.disabled = false;
      //     })
      //   }, 100);
      // });
    }
    //
    // if (textarea.id == 'merchant_description') {
    //   // CKEDITOR.instances['merchant_description'].element.$.required = true
    //   CKEDITOR.instances['merchant_description'].on('required', function (event) {
    //     alert('出品者説明 は入力が必須項目です');
    //     CKEDITOR.instances['merchant_description'].showNotification( '入力が必須項目です', 'warning' );
    //     event.cancel();
    //   });
    // }

    config.language = 'ja';
    // The location of an external file browser, that should be launched when "Browse Server" button is pressed in the Link tab of Image dialog.
    config.filebrowserImageBrowseLinkUrl = "/ckeditor/pictures";
    // The location of an external file browser, that should be launched when "Browse Server" button is pressed in the Image dialog.
    config.filebrowserImageBrowseUrl = "/ckeditor/pictures";
    // The location of a script that handles file uploads in the Image dialog.
    // config.filebrowserImageUploadUrl = "/ckeditor/pictures";
    // repository bug fix
    config.filebrowserImageUploadUrl = "/ckeditor/pictures?command=QuickUpload&type=Images";
    // config.height = 1000;        // 500 pixels high.
    // for iframe..
    config.allowedContent = true;

    // config.toolbarGroups = [
    //   { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
    //   { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ] },
    //   { name: 'links' },
    //   { name: 'insert' },
    //   { name: 'forms' },
    //   { name: 'tools' },
    //   { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
    //   { name: 'others' },
    //   '/',
    //   { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
    //   { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
    //   { name: 'styles' },
    //   { name: 'colors' },
    //   { name: 'about' }
    // ];
    // config.removeButtons = 'Underline,Subscript,Superscript,Image';
    // config.format_tags = 'p;h1;h2;h3;pre';
    // config.removeDialogTabs = 'image:advanced;link:advanced;link:upload;image:Upload;table:advanced;image:Link';
    const isReadOnly = $(textarea).hasClass("ckeditor-read-only");
    config.readOnly = isReadOnly;
  };
  $(init);
})(jQuery);

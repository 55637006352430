import axios from 'axios';

/**
 * 承認/却下リンク
 * @require axios
 */
;($ => {
  const bulkExpr = '.js-bulk-review-request-link';
  const singleExpr = '.js-review-request-link';
  const formExpr = '.js-bulk-action-form';
  const checkboxExpr = '.js-bulk-action-checkbox';
  const commentModalExpr = '#js-review-request-modal';
  let $form, $commentModal;
  let productIds = [];
  let domain = '';
  let mode = ''; // ('single'|'bulk')

  const init = () => {
    $form = $(formExpr);
    if (!$form.length) return;
    if (!$form.find(bulkExpr).length && !$form.find(singleExpr).length) return;
    domain = $form.data('domain');
    $commentModal = $(commentModalExpr);
    $form.
      on('click', bulkExpr, onBulkClicked).
      on('click', singleExpr, onSingleClicked);
    $commentModal.on('submit', 'form', onSubmitted);
  };

  const onBulkClicked = e => {
    const $element = $(e.currentTarget);
    const $checkboxes = $form.find(checkboxExpr).filter(':checked');
    const href = $element.attr('href');
    mode = 'bulk';
    if (!$checkboxes.length) {
      alert('対象のデータが選択されていません。');
      return false;
    }
    productIds = [];
    $checkboxes.each((key, element) => {
      productIds.push($(element).val());
    });
    if ($element.data('comment')) {
      _commentModal(href, $element.data('confirm'));
      return false;
    }
    if ($element.data('confirm')) {
      if (!confirm($element.data('confirm'))) return false;
    }
    _submit(href);
    return false;
  };

  const onSingleClicked = e => {
    const $element = $(e.currentTarget);
    const href = $element.attr('href');
    mode = 'single';
    if ($element.data('comment')) {
      _commentModal(href, $element.data('confirm'));
      return false;
    }
    if ($element.data('confirm')) {
      if (!confirm($element.data('confirm'))) return false;
    }
    _submit(href);
    return false;
  };

  const onSubmitted = e => {
    const $element = $(e.currentTarget);
    const $comment = $element.find('[name="review_request[comment]"]');
    if ($element.data('confirm')) {
      if (!confirm($element.data('confirm'))) return false;
    }
    _submit($element.attr('action'), $comment.val());
    return false;
  };

  const _commentModal = (href, confirm) => {
    const $form = $commentModal.find('form');
    const $comment = $form.find('[name="review_request[comment]"]');
    $form.data('confirm', confirm).
      attr('action', href);
    $comment.val('');
    $commentModal.modal('show');
  };

  const _submit = async (requestUrl, comment) => {
    const requestData = { shop: domain };
    if (mode == 'bulk') {
      requestData.ids = productIds;
    }
    if (comment != null) {
      requestData.comment = comment;
    }
    try {
      const response = await axios.post(requestUrl, requestData);
    } catch (error) {
      console.error(error);
    }
    location.reload();
  };

  $(init);

})(jQuery);
